import { useState, useRuntimeConfig } from '#app'
import { convert } from 'html-to-text';

export const fixDrupalLink = (text: string | undefined): string => {
  if (!text) {
    return ''
  }

  const backendRegex = /(?!\/backend\/(sites|admin))\/backend/g
  const mediaOembedRegex = /(?:^|[^/backend])\/media\/oembed/g

  return text
    .replaceAll(backendRegex, '')
    .replaceAll(mediaOembedRegex, '/backend/media/oembed');
}

export const fixContentType = (bundle) => {
  return bundle ? bundle.replace('-', '_') : ''
}

export const getPreviewBackUrl = (uuid, nid?, contentType?) => {
  const path = nid ? nid + '/edit' : 'add/' + contentType
  return '/backend/node/' + path + '?uuid=' + uuid
}

const isValidNumber = (str: string) => /^-?\d+$/.test(str)

export const getIdFromRoute = (paramName = 'slug') => {
  const route = useRoute()
  const extractNid = (id) => isValidNumber(id) ? id : undefined;

  // Try to get the ID from the query parameters
  if (route.query?.nid) {
    return extractNid(route.query?.nid);
  }
  // Then try to get the ID from the page path
  const pagePath = route.params[paramName];
  if (pagePath && pagePath.includes("_")) {
    return extractNid(pagePath.split('_').pop());
  }
}

export const formatDate = (date, options?) => {
  return new Date(date).toLocaleDateString(
    'fr',
    options || {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
  )
}

export const formatDateDay = (date, format?) => {
  return formatDate(date, { day: format || 'numeric' })
}

export const formatDateMonth = (date, format?) => {
  return formatDate(date, { month: format || 'short' })
}

export const formatDateYear = (date, format?) => {
  return formatDate(date, { year: format || 'short' })
}

export const uniqueId = () => {
  const dateString = Date.now().toString(36)
  const randomness = Math.random().toString(36).substring(2)
  return `id-${dateString}-${randomness}`
}

export const isNameValid = (name) => {
  const regex = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/
  return regex.test(name) && name !== undefined
}

export const isEmailValid = (email) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return regex.test(email)
}

export const isPhoneValid = (phoneNumber) => {
  const regex = /^[0-9]{10}$/
  return regex.test(phoneNumber)
}

export const isRequiredValid = (text) => {
  return text !== undefined && text !== ''
}

export const isEmailExistError = (email: string) => {
  return email.match(/The email address (.+?) is already taken\./)
}

export const isEmailRegistered = (email: string) => {
  return email.match(/(.+?) est déjà inscrit à cet évènement\./)
}

export const isUsernameExistError = (name: string) => {
  return name.match(/The username (.+?) is already taken\./)
}

export const decodeHTMLEntities = (encodedString: string): string => {
  const parser = new DOMParser()
  const dom = parser.parseFromString(
    `<!doctype html><body>${encodedString}`,
    'text/html'
  )
  return dom.body.textContent || ''
}

export const htmlToPlainText = (html: string): string =>  {
  return convert(html, { wordwrap: false })
}

export const checkUserRole = (
  userRoles: string[],
  roles: string[]
): boolean => {
  if (typeof userRoles === 'string') {
    userRoles = decodeHTMLEntities(userRoles).split(', ')
  }
  return (
    roles === undefined ||
    (userRoles && userRoles.some((role) => roles.includes(role)))
  )
}

export const hasRoles = (roles: string[]): boolean => {
  const user = useState('user')
  return user.value?.roles && checkUserRole(user.value.roles, roles)
}

export const setMessage = (msg: string) => {
  const message = useState('systemMessage')
  message.value = {txt: msg}
}

export const fullPath = (path: string) => {
  const runtimeConfig = useRuntimeConfig()
  const origin = fixDrupalLink(runtimeConfig.public.API_BASE_URL)
  return path ? origin + path : ''
}

export const isBackendLink = (path: string | undefined): boolean => {
  return Boolean(path && path.startsWith('/backend/'));
}

export const formatPhoneNumber = (num: string ) => {
  return num.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
}


export const generatePDF = async (pageUrl: string) => {
  if (!pageUrl) return

  try {
    const response = await fetch('/api/pdf/generate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        url: pageUrl,
        options: {
          format: 'A4',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          timeout: 30000
        }
      })
    })

    const result = await response.json()

    if (result.success) {
      window.open(result.downloadUrl, '_blank')
    } else {
      throw new Error(result.message || 'PDF generation failed')
    }
  } catch (error) {
    console.error('Error:', error)
  } finally {
  }
}